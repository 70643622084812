import { cookieStorage, createConfig, createStorage } from 'wagmi';
import { walletConnect, coinbaseWallet, safe } from '@wagmi/connectors';

import { createClient, http } from 'viem';
import { mainnet } from 'viem/chains';
import configuration from './configuration';

// Get projectId at https://cloud.walletconnect.com
export const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_ID!;

if (!projectId) throw new Error('Project ID is not defined');

const metadata = {
	name: 'Giveth',
	description:
		'Get rewarded for giving to for-good projects with zero added fees. Donate crypto directly to thousands of for-good projects, nonprofits &amp; charities!',
	url: 'https://giveth.io',
	icons: ['https://giveth.io/images/currencies/giv/24.svg'],
};

const chains = configuration.EVM_CHAINS;

// Create wagmiConfig
export const wagmiConfig = createConfig({
	chains: chains, // required
	connectors: [
		walletConnect({
			projectId,
			metadata,
		}),
		coinbaseWallet({ appName: 'Giveth', version: '3' }),
		safe({
			allowedDomains: [/app.safe.global$/],
		}),
	],
	ssr: true,
	storage: createStorage({
		storage: cookieStorage,
	}),
	client({ chain }) {
		// TODO: we must manage this for all chains in a better way
		// basically to stop using viem's public transport
		// leaving this as a hotfix to keep it quick
		const infuraKey = process.env.NEXT_PUBLIC_INFURA_API_KEY;

		const customHttp =
			chain.id === mainnet.id && infuraKey
				? http(`https://mainnet.infura.io/v3/${infuraKey}`)
				: http();

		return createClient({ chain, transport: customHttp });
	},
});
